import React, { useMemo } from 'react';
import { getNextLoginImage } from '../utils/storage';
import styled from 'styled-components/macro';

export const LoginRandomImage: React.FC = () => {
  const image = useMemo(() => getNextLoginImage(), []);

  return <Img src={`/images/login/login_${image}.png`} alt={'marketing'} />;
};

const Img = styled.img`
  flex: 1;
  min-width: 0;
  object-fit: cover;

  @media screen and (max-width: 1919px) {
    display: none;
  }
`;

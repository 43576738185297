import React, { FormEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { patchResetPassword } from '../../common/api/apiClient';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../AppRoutes';
import { Body, Headline } from '../../shared/styled-components/styled_text';
import { IconInput } from '../../shared/components/IconInput';
import { FilledButton } from '../../shared/styled-components/styled_buttons';
import { Container } from './components/styled';
import { FlexCol } from '../../shared/FlexCol';
import { useBoundingClient } from '../../common/utils/hooks';
import { RegEx } from '../../common/utils/RegEx';

export const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState<string | null>();
  const [phone, setPhone] = useState<string | null>();
  const [token, setToken] = useState<string | null>();
  const [password1, setPassword1] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');
  const form = useRef<HTMLFormElement>(null);
  const { client: formClient } = useBoundingClient(form);

  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setEmail(params.get('email'));
    setToken(params.get('token'));
    setPhone(params.get('phone'));
  }, []);
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!email && !phone) return;
    try {
      await patchResetPassword(email ? 'email' : 'phone', email ?? phone!, token!, password1!);
      alert('비밀번호 변경이 완료되었어요.');
      navigate(appRoutes.login.path());
    } catch (e) {
      alert(e);
    }
  };

  return (
    <Container form={formClient}>
      <Form ref={form} onSubmit={handleSubmit}>
        <FlexCol align={'flex-start'} width={'100%'} gap={'0.2rem'}>
          <Headline size={'m'}>
            캔디데이트 비즈에서 사용할 <br />
            새로운 비밀번호를 입력해주세요.
          </Headline>
          <Body size={'m'}>영문+숫자 8자 이상</Body>
        </FlexCol>

        <FlexCol align={'flex-start'} width={'100%'} gap={'1.6rem'} style={{ marginTop: '2rem' }}>
          <IconInput
            label={'새 비밀번호'}
            value={password1}
            onChange={setPassword1}
            placeholder={'새 비밀번호'}
            pattern={RegEx.Password}
            type={'password'}
            required={true}
            patternMessage={'영문+숫자 8자 이상 입력해주세요.'}
            style={{ width: '100%' }}
          />
          <IconInput
            label={'새 비밀번호 확인'}
            value={password2}
            onChange={setPassword2}
            placeholder={'다시 한 번 입력해주세요'}
            pattern={val => val === password1}
            type={'password'}
            required={true}
            patternMessage={'비밀번호가 일치하지 않습니다'}
            style={{ width: '100%' }}
          />
        </FlexCol>

        <FilledButton
          size={'m'}
          color={'primary'}
          style={{ width: '100%', marginTop: '3rem' }}
          disabled={password1 !== password2 || !RegEx.Password.test(password1)}
        >
          비밀번호 변경하기
        </FilledButton>
      </Form>
    </Container>
  );
};

const Form = styled.form`
  width: 32rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  padding: 3rem 2rem;
  background: white;
  border: 1px solid #e9ebed;
  border-radius: 16px;
  opacity: 0.95;

  &:before {
    content: '';
    width: 3.2rem;
    height: 3.2rem;
    background-image: url('/images/common/lock_black.svg');
    background-size: cover;
  }
`;
